exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-kohdehaku-index-js": () => import("./../../../src/pages/kohdehaku/index.js" /* webpackChunkName: "component---src-pages-kohdehaku-index-js" */),
  "component---src-pages-talo-oppaat-index-js": () => import("./../../../src/pages/talo-oppaat/index.js" /* webpackChunkName: "component---src-pages-talo-oppaat-index-js" */),
  "component---src-pages-tila-vuokrattu-index-js": () => import("./../../../src/pages/tila-vuokrattu/index.js" /* webpackChunkName: "component---src-pages-tila-vuokrattu-index-js" */),
  "component---src-pages-valittajille-index-js": () => import("./../../../src/pages/valittajille/index.js" /* webpackChunkName: "component---src-pages-valittajille-index-js" */),
  "component---src-pages-vertaile-index-js": () => import("./../../../src/pages/vertaile/index.js" /* webpackChunkName: "component---src-pages-vertaile-index-js" */),
  "component---src-templates-category-archive-js": () => import("./../../../src/templates/CategoryArchive.js" /* webpackChunkName: "component---src-templates-category-archive-js" */),
  "component---src-templates-fund-type-page-js": () => import("./../../../src/templates/FundTypePage.js" /* webpackChunkName: "component---src-templates-fund-type-page-js" */),
  "component---src-templates-house-guide-js": () => import("./../../../src/templates/HouseGuide.js" /* webpackChunkName: "component---src-templates-house-guide-js" */),
  "component---src-templates-investors-search-page-js": () => import("./../../../src/templates/InvestorsSearchPage.js" /* webpackChunkName: "component---src-templates-investors-search-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-archive-js": () => import("./../../../src/templates/PostArchive.js" /* webpackChunkName: "component---src-templates-post-archive-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-release-archive-js": () => import("./../../../src/templates/ReleaseArchive.js" /* webpackChunkName: "component---src-templates-release-archive-js" */),
  "component---src-templates-release-js": () => import("./../../../src/templates/Release.js" /* webpackChunkName: "component---src-templates-release-js" */),
  "component---src-templates-search-page-js": () => import("./../../../src/templates/SearchPage.js" /* webpackChunkName: "component---src-templates-search-page-js" */),
  "component---src-templates-search-result-js": () => import("./../../../src/templates/SearchResult.js" /* webpackChunkName: "component---src-templates-search-result-js" */),
  "component---src-templates-site-contact-page-js": () => import("./../../../src/templates/SiteContactPage.js" /* webpackChunkName: "component---src-templates-site-contact-page-js" */),
  "component---src-templates-site-js": () => import("./../../../src/templates/Site.js" /* webpackChunkName: "component---src-templates-site-js" */),
  "component---src-templates-site-type-page-js": () => import("./../../../src/templates/SiteTypePage.js" /* webpackChunkName: "component---src-templates-site-type-page-js" */),
  "component---src-templates-unit-js": () => import("./../../../src/templates/Unit.js" /* webpackChunkName: "component---src-templates-unit-js" */)
}

